/*
  Override plugin default variables

  Full list of variables:
  https://sachinchoolur.github.io/lightgallery.js/docs/api.html#sass
*/

$backdrop-opacity: .5;
// path
$lg-path-fonts: 'fonts';
$lg-path-images: 'img';
