/*
  If this component requires a plugin, check if this plugin is also installed in the node_modules folder.
*/

// TODO: Lightbox theme optimieren (dark to light)

@import
"./variables",
"src/main/scss/mixins/media-query",
"src/main/scss/mixins/state-indicator",
"src/main/scss/functions/find-color-invert",
'lightgallery.js/src/sass/lightgallery';

.lg {
  // Body not scrollable
  &-on {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
  }

  &-outer {
    z-index: var(--zindex-components);
  }

  &-backdrop {
    background-color: rgba(0,0,0,0.4);
    z-index: var(--zindex-components);
  }

  &-sub-html {
    * {
      color: #fff;
      margin: 0;
      padding: 0;
    }
  }
}

// Themes
.lg-outer.is--single {
  .lg {
    &-toolbar {
      display: none;
    }
  }
}
